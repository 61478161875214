/* Looking ===========================================================*/
.looking-centerbox {
    padding: 8em 1.5em;
    position: relative;
}

.looking-centerbox .h-looking-m1 {
    text-align: center;
    position: relative;
    z-index: 4;
    display: block;
    padding: 1.5em 1em 2em 1em;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

@media (max-width: 767px) {
    .looking-centerbox {
        padding: 5em 1em;
    }
}

@media (max-width: 567px) {
    .looking-centerbox {
        padding: 3em 1em;
    }

        .looking-centerbox .h-looking-m1 .btn {
            width: 100%;
            display: block;
            margin-bottom: 5px;
        }
}

.looking-centerbox .h-looking-m1 .section-heading {
    padding-bottom: 16px;
}

.looking-centerbox .h-looking-m1 .section-title {
    margin-top: 0;
}

/* Colors =====*/
.looking-centerbox .h-looking-m1 {
    background: rgba(27, 33, 45, 0.8);
}
