


.h-inventory-carousel-m2 .carousel-inner {
    width: 100%;
    overflow: hidden;
    position: relative;
    clear: both;
}


.h-inventory-carousel-m2 .carousel-control {
    width: 4%;
    opacity: 1;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}


    .h-inventory-carousel-m2 .carousel-control.left,
    .h-inventory-carousel-m2 .carousel-control.right {
        margin-left: 0px;
        margin-right: 0px;
        background-image: none;
    }


    .h-inventory-carousel-m2 .carousel-control .glyphicon-chevron-left,
    .h-inventory-carousel-m2 .carousel-control .glyphicon-chevron-right,
    .h-inventory-carousel-m2 .carousel-control .icon-next,
    .h-inventory-carousel-m2 .carousel-control .icon-prev {
        width: 60px;
        height: 60px;
        margin-top: -15px;
        font-size: 30px;
        text-align: center;
        vertical-align: middle;
        line-height: 58px;
        background-color: rgba(255,255,255, 0.5);
        color: #595959;
        z-index: 55000;
    }

@media(max-width: 768px) {
    .h-inventory-carousel-m2 .carousel-control .glyphicon-chevron-left,
    .h-inventory-carousel-m2 .carousel-control .glyphicon-chevron-right,
    .h-inventory-carousel-m2 .carousel-control .icon-next,
    .h-inventory-carousel-m2 .carousel-control .icon-prev {
        width: 45px;
        height: 45px;
        font-size: 25px;
        line-height: 45px;
    }
}


.h-inventory-carousel-m2 .extra1,
.h-inventory-carousel-m2 .extra2,
.h-inventory-carousel-m2 .extra3 {
    display: none;
}



@media (max-width: 1492px) and (min-width: 992px) {
    .h-inventory-carousel-m2 .vehicle-link {
        padding: 8px 5px;
    }

    .h-inventory-carousel-m2 .vehicle-title {
        font-size: 17px;
    }

    .h-inventory-carousel-m2 .vehicle-price {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .h-inventory-carousel-m2 .vehicle-description {
        display: none;
    }

    .h-inventory-carousel-m2 .vehicle-miles {
        padding-top: 0;
        text-align: left;
    }

    .h-inventory-carousel-m2 .vehicle-price-miles [class*='col-'] {
        width: 100%;
    }
}


@media only screen and (min-width: 768px) {
    @media all and (transform-3d), (-webkit-transform-3d) {
        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.active.left,
        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.prev {
            transform: translate3d(-50%, 0, 0);
            left: 0;
        }

        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.active.right,
        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.next {
            transform: translate3d(50%, 0, 0);
            left: 0;
        }
    }

    .h-inventory-carousel-m2 .carousel-inner > .active.left {
        left: -50%;
    }

    .h-inventory-carousel-m2 .carousel-inner > .active.right {
        left: 50%;
    }

    .h-inventory-carousel-m2 .carousel-inner > .next {
        left: 50%;
    }

    .h-inventory-carousel-m2 .carousel-inner > .prev {
        left: -50%;
    }

    .h-inventory-carousel-m2 .extra1 {
        display: block;
    }
}


@media only screen and (min-width: 992px) {
    @media all and (transform-3d), (-webkit-transform-3d) {
        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.active.left,
        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.prev {
            transform: translate3d(-25%, 0, 0);
            left: 0;
        }

        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.active.right,
        .h-inventory-carousel-m2 .carousel-inner > .carousel-item.next {
            transform: translate3d(25%, 0, 0);
            left: 0;
        }
    }

    .h-inventory-carousel-m2 .carousel-inner > .active.left {
        left: -25%;
    }

    .h-inventory-carousel-m2 .carousel-inner > .active.right {
        left: 25%;
    }

    .h-inventory-carousel-m2 .carousel-inner > .next {
        left: 25%;
    }

    .h-inventory-carousel-m2 .carousel-inner > .prev {
        left: -25%;
    }

    .h-inventory-carousel-m2 .extra2,
    .h-inventory-carousel-m2 .extra3 {
        display: block;
    }
}




/* Homepage Vehicle Cards
===========================================================*/


/* Common style */
.h-inventory-carousel-m2 .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    background: #000;
    text-align: center;
    cursor: pointer;
}

    .h-inventory-carousel-m2 .grid figure img {
        position: relative;
        display: block;
        max-width: 100%;
        opacity: 0.8;
    }

    .h-inventory-carousel-m2 .grid figure figcaption {
        padding: 2em;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.25em;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

        .h-inventory-carousel-m2 .grid figure figcaption::before,
        .h-inventory-carousel-m2 .grid figure figcaption::after {
            pointer-events: none;
        }

        .h-inventory-carousel-m2 .grid figure figcaption,
        .h-inventory-carousel-m2 .grid figure figcaption > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

            /* Anchor will cover the whole item by default */
            /* For some effects it will show as a button */
            .h-inventory-carousel-m2 .grid figure figcaption > a {
                /*z-index: 1000;*/
                text-indent: 200%;
                white-space: nowrap;
                font-size: 0;
                opacity: 0;
            }

    .h-inventory-carousel-m2 .grid figure h2 {
        word-spacing: -0.15em;
        font-weight: 300;
    }

        .h-inventory-carousel-m2 .grid figure h2 span {
            font-weight: 800;
        }

    .h-inventory-carousel-m2 .grid figure h2,
    .h-inventory-carousel-m2 .grid figure p {
        margin: 0;
    }

    .h-inventory-carousel-m2 .grid figure p {
        letter-spacing: 1px;
        font-size: 68.5%;
    }



/*---------------*/
/***** Lily Hover Effect *****/
/*---------------*/

.h-inventory-carousel-m2 figure.effect-lily img {
    max-width: none;
    width: -webkit-calc(100% + 50px);
    width: calc(100% + 50px);
    opacity: 0.5;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-40px,0, 0);
    transform: translate3d(-40px,0,0);
}

.h-inventory-carousel-m2 figure.effect-lily figcaption {
    text-align: left;
}

    .h-inventory-carousel-m2 figure.effect-lily figcaption > div {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2em;
        width: 100%;
    }

.h-inventory-carousel-m2 figure.effect-lily:hover figcaption > div {
    background-color: rgba(0,0,0,0.25);
}

.h-inventory-carousel-m2 figure.effect-lily h2,
.h-inventory-carousel-m2 figure.effect-lily p {
    -webkit-transform: translate3d(0,40px,0);
    transform: translate3d(0,40px,0);
}

.h-inventory-carousel-m2 figure.effect-lily h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    font-size: 24px;
}

.h-inventory-carousel-m2 figure.effect-lily p {
    color: rgba(255,255,255,0.8);
    opacity: 0;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
    transition: opacity 0.2s, transform 0.35s;
}

.h-inventory-carousel-m2 figure.effect-lily:hover img,
.h-inventory-carousel-m2 figure.effect-lily:hover p {
    opacity: 1;
}


.h-inventory-carousel-m2 figure.effect-lily:hover h2 {
    font-size: 20px;
}

.h-inventory-carousel-m2 figure.effect-lily:hover img,
.h-inventory-carousel-m2 figure.effect-lily:hover h2,
.h-inventory-carousel-m2 figure.effect-lily:hover p {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.h-inventory-carousel-m2 figure.effect-lily:hover p {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
}
