/* the welcome section uses the header module, so the rules are adjusted to
    affect the header module within the section of the hero image
*/

.welcome {
    margin-top: 0;
    padding: 0;
}

.welcome::after {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    overflow: hidden;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.welcome .welcome-m1 {
    padding: 150px 0;
    z-index: 4;
    position: relative;
    text-align: left;
}

@media (max-width: 767px) {
  .welcome .welcome-m1 {
    padding: 70px 0;
  } 
}

.welcome .welcome-m1 .welcome-name {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.welcome .welcome-m1 .welcome-name span {
  font-weight: 400;
  display: block;
  font-size: 17px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.welcome .welcome-m1 .welcome-phone {
    display: none;
}