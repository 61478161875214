/* Welcome ===========================================================*/
.welcome-leftbox {
    padding: 8em 1.5em;
    position: relative;
}

.welcome-leftbox .welcome-section h2.welcome-name span {
    display: block;
    font-size: 16px;
}

.welcome-leftbox .welcome-m1 {
    text-align: left;
    position: relative;
    z-index: 4;
    display: inline-block;
    padding: 30px 50px 30px 30px;
}

@media (max-width: 767px) {
    .welcome-leftbox .welcome {
        padding: 5em 1em;
    }
}

@media (max-width: 567px) {
    .welcome-leftbox {
        padding: 3em 1em;
    }

    .welcome-leftbox .welcome-m1 {
        padding: 1.5em 1em 2em 1em;
    }


    .welcome-leftbox .btn {
        width: 100%;
        display: block;
    }
}

/* Colors =====*/
        .welcome-leftbox .welcome-m1 {
    background: rgba(46, 47, 50, 0.8);
    color: #fff;
}
