/* Looking ===========================================================*/
.looking-overlay {
    position: relative;
    text-align: center;
}

    .looking-overlay::after {
        overflow: hidden;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .looking-overlay .h-looking-m1 {
        padding: 8em 1.5em;
        position: relative;
        z-index: 4;
        display: block;
    }

@media (max-width: 767px) {
    .looking-overlay .h-looking-m1 {
        padding: 5em 1em;
    }
}

@media (max-width: 567px) {
    .looking-overlay .h-looking-m1 {
        padding: 3em 1em;
        width: 100%;
    }

        .looking-overlay .h-looking-m1 .btn {
            width: 100%;
            display: block;
            margin-bottom: 5px;
        }
}

.looking-overlay .h-looking-m1 .section-heading {
    padding-bottom: 16px;
}

.looking-overlay .h-looking-m1 .section-title {
    margin-top: 0;
}

.looking-overlay .h-looking-m1 .section-title::after {
    display: none;
}