/*  Looking
  ==================================================*/
.looking {
    padding: 80px 0 80px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.h-looking-m1 {
    position: relative;
    z-index: 9;
    display: inline-block;
    padding: 30px 150px 30px 30px;
}

.h-looking-m1 .section-title {
    margin-top: 0;
}

.h-looking-m1 .section-heading {
    padding-bottom: 20px;
}

.h-looking-m1 .btn {
    margin-bottom: 5px;
}


@media(max-width: 767px) {
    .h-looking-m1 {
        padding: 20px 50px 20px 20px;
    }
}

@media(max-width: 400px) {
    .h-looking-m1 {
        padding: 20px;
        width: 100%;
    }

    .h-looking-m1 .btn {
        width: 100%;
        display: block;
    }
}