/*===================================================================================================
  Global Features
===================================================================================================*/
body {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #595959;
    background-color: #fff;
    position: relative;
}

article, section, aside, hgroup, nav, header, footer, figure, figcaption {
  display: block;
}

.nav {
    z-index: 1000;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    min-height: 0;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

@media(min-width: 769px){
  .ellipsis { 
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
  }
}

.center-inline {
	display: inline;
	margin-left: auto;
	margin-right: auto;
}

@media (min-width: 768px) {
    #google_translate_element {
        position: relative;
    }

        #google_translate_element > .goog-te-gadget {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5000;
        }
}

/* Image Responsive
===========================================================*/
    .carousel-inner > .item > a > img, .carousel-inner > .item > img, .img-responsive, .thumbnail a > img, .thumbnail > img {
        display: block;
        max-width: 100%;
        height: auto;
        width: 100%;
    }


    /* Address
===========================================================*/
    address {
        margin-bottom: 20px;
        font-style: normal;
        line-height: 1.5;
    }

    address p {
        padding-left: 18px;
        text-indent: -12px;
        margin-bottom: 3px;
    }

    address p i {
        width: 12px;
        text-align: center;
    }

    address p a {
        color: inherit;
    }



    /* Alert
===========================================================*/

    .alert-info {
        color: #333;
        background: #E2E2E2 none repeat scroll 0 0;
        border-color: #E2E2E2;
        text-shadow: 0 0 0 rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 0 rgba(255, 255, 255, 0.25) inset, 0 0 0 rgba(0, 0, 0, 0.05);
    }



    /* Container
===========================================================*/

    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }

    @media (min-width: 820px) {
        .container {
            width: 800px;
        }
    }

    @media (min-width: 870px) {
        .container {
            width: 850px;
        }
    }

    @media (min-width: 950px) {
        .container {
            width: 930px;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            width: 1170px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            width: 1240px;
        }
    }



    /* Table Styles
===========================================================*/

    .c-table {
        display: table;
        width: 100%;
    }

    .c-tablecell {
        display: table-cell;
        vertical-align: middle;
    }

    .business-hours-table .c-tablecell:last-of-type {
        text-align: right;
    }


    /* Links & Buttons
===========================================================*/
    a {
        color: #595959;
        text-decoration: none;
        font-weight: 400;
    }

        a:hover {
            color: #747474;
            text-decoration: none;
        }

        .btn:focus,
        .btn:active:focus,
        .btn.active:focus,
        .btn.active,
        .btn:active,
        a:focus,
        a:active {
            outline: 0 auto -webkit-focus-ring-color;
        }

    .btn {
        letter-spacing: .04em;
        font-weight: 400;
        padding: 10px 13px;
        line-height: 1.1;
        text-decoration: none;
        border-radius: 0;
        white-space: normal;
    }

    .btn-xl {
        font-size: 16px;
        padding: 11px 15px 10px 15px;
    }

    .btn-xlg {
        font-size: 18px;
        padding: 18px 38px;
    }

    .btn-super {
        height: 55px;
        font-size: 17px;
        padding: 13px 25px 12px 25px;
    }

    .btn-sm {
        height: 36px;
        line-height: 19px;
        font-size: 15px;
        padding: 8px 13px;
    }

    .btn-xs {
        padding: 1px 8px;
        height: auto;
    }


    .btn-default,
    .btn-default:focus {
        background-image: none;
        text-shadow: 0 0 0;
        text-shadow: none;
        filter: none;
    }

        .btn-default:hover,
        .btn-default:active,
        .btn-default.active {
            text-shadow: none;
            filter: none;
            text-decoration: none;
            background-image: none;
        }

        .btn-default:disabled,
        .btn-default.disabled {
            background-color: #E6E6E6;
        }



    .btn-link,
    .btn-link:focus {
        color: #0083ae;
        text-decoration: none;
        border-bottom: 1px solid #0083ae;
    }

        .btn-link:hover,
        .btn-link:active,
        .btn-link.active {
            color: #00698c;
            text-decoration: none;
            border-bottom-color: #00698c;
        }

    /* Form button font sizes should change to match inputs */
    @media(max-width: 767px){
        .modal-body .btn-primary,
        .modal-body .btn-default,
        .contact-m1 .btn-primary,
        .form-group .btn-primary {
            font-size: 16px;
        }
    }

    /*===================================================================================================
  Form Styles
===================================================================================================*/

    @media(min-width: 768px) {
        .input-xlg {
            height: 46px;
            padding: 10px 16px;
            font-size: 16px;
            line-height: 1.3333333;
            border-radius: 3px;
        }

        textarea.input-xlg {
            min-height: 169px;
        }
    }



    /* form-control
===========================================================*/
    .form-control {
        display: block;
        width: 100%;
        height: 50px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857;
        color: #555;
        background-color: #ffffff;
        background-image: none;
        border: 1px solid #ddd;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    }

        .form-control:focus {
            border: 1px solid #b0b0b0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .form-control::-moz-placeholder {
            color: #828282;
            filter: alpha(opacity=100);
            opacity: 1;
        }

        .form-control:-ms-input-placeholder {
            color: #828282;
        }

        .form-control::-webkit-input-placeholder {
            color: #828282;
        }

        @media (max-width: 767px) {
            .form-control {
                font-size: 16px;
            }
        }

    .input-group-addon {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        text-align: center;
        background-color: #EEE;
        border: 1px solid #CCC;
        border-radius: 0;
    }

    /* Required
===========================================================*/
    .form-group label.required:after {
        font-family: FontAwesome;
        content: "\f069";
        vertical-align: top;
        font-size: 7px;
        margin-left: 5px;
        color: #B6B6B6;
    }




    /* form-error
===========================================================*/
    .has-error .form-control {
        border-color: #c72931;
        -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
        box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
    }

        .has-error .form-control:focus {
            border-color: #c72931;
            -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
            box-shadow: inset 0 0 0 rgba(0, 0, 0, .075), 0 0 0 #FF615E;
        }

    .has-error .checkbox,
    .has-error .checkbox-inline,
    .has-error .control-label,
    .has-error .help-block,
    .has-error .radio-block-label,
    .has-error .radio,
    .has-error .radio-inline,
    .has-error.checkbox label,
    .has-error.checkbox-inline label,
    .has-error.radio label,
    .has-error.radio-inline label {
        color: #c72931;
    }

    .has-error input[type="radio"] {
        border-color: #c72931;
    }

    .has-feedback label ~ .form-control-feedback {
        top: 31px;
    }

    .has-feedback .form-control-feedback {
        top: 7px;
    }

    .help-block {
        display: block;
        margin-top: 0;
        padding: 4px 8px;
        margin-bottom: 10px;
        font-size: 13px;
        color: #737373;
        text-align: center;
    }

    .has-error .help-block {
        background-color: #c72931;
        color: #fff;
    }

    .recall {
        margin-bottom: 25px;
    }

    .message-counter {
        text-align: right;
    }
    .counter-error {
        color: red;
    }

    /* Checkboxes & Radios
===========================================================*/

    /* ul/ol */
    .unindented {
        margin-left: -20px;
        color: #595959;
    }


    .radio label, .checkbox label {
        display: inline;
        font-weight: 400;
        cursor: pointer;
    }

    label {
        font-weight: 600;
        font-size: 13px;
        color: #595959;
    }

    .c-margin {
        margin-top: 15px;
    }

    .radio label,
    .checkbox label {
        padding-left: 28px;
    }

    .radio input[type=radio],
    .radio-inline input[type=radio],
    .checkbox input[type=checkbox],
    .checkbox-inline input[type=checkbox] {
        margin-left: -28px;
    }

    input[type='radio'] {
        border-radius: 50%;
    }

    input[type='checkbox'] {
        border-radius: 3px;
    }

    input[type='checkbox'],
    input[type='radio'] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #919191;
        margin: 0;
    }

        input[type='checkbox']:checked,
        input[type='radio']:checked {
            background: #595959;
            border-color: #595959;
        }

            input[type='checkbox']:checked:after,
            input[type='radio']:checked:after {
                font-family: FontAwesome;
                content: "\f00c";
                color: #fff;
                padding: 0 3px;
                line-height: 18px;
                font-size: 12px;
                vertical-align: top;
            }

        input[type='checkbox']:focus,
        input[type='checkbox']:checked,
        input[type='radio']:focus,
        input[type='radio']:checked {
            outline: none;
        }

    .radio, .checkbox {
        margin-top: 8px;
        margin-bottom: 8px;
    }


    @media(max-width: 992px) {
        .radio, .checkbox {
            margin-top: 15px;
            margin-bottom: 15px;
            margin-right: 15px;
        }

            .radio label, .checkbox label {
                font-size: 15px;
            }
    }


    /* expand-form
===========================================================*/

    .expand-form .row {
        margin-left: -30px;
        margin-right: -30px;
    }

    .expand-form [class*='col-'] {
        padding-left: 30px;
        padding-right: 30px;
    }


    /* Tighter-Form
===========================================================*/

    .tighter-form .row {
        padding-left: 10px;
        padding-right: 10px;
    }

    .tighter-form [class*='col-'] {
        padding-left: 7px;
        padding-right: 7px;
    }

    .tighter-form.panel-default > .panel-heading {
        background-color: #f6f6f6;
        border-bottom: 0;
        font-weight: 700;
        color: #333;
        font-size: 16px;
    }

    .no-gutter > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }

    .form-inline-width {
        width: 170px;
        display: inline-block;
    }


    /* Touching Grid
===========================================================*/
    .touch-form .row {
        padding-left: 15px;
        padding-right: 15px;
    }

    .touch-form [class*='col-'] {
        padding-left: 0;
        padding-right: 0;
    }



    /* Modal
===========================================================*/

    .modal {
        z-index: 55000;
    }

    .modal-backdrop {
        z-index: 54000;
    }

        .modal-backdrop.in {
            filter: alpha(opacity=70);
            opacity: .7;
        }

    .modal-footer {
        border-top: 0;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
    }

        .modal-footer p {
            margin-bottom: 0;
        }

    .modal-header {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border: 0 solid #fff;
    }

    .modal-heading,
    .modal-header {
        background-color: #0083ae;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .04em;
        border-bottom: 1px solid #ddd;
    }

        .modal-heading .close,
        .modal-header .close {
            color: #fff;
            text-shadow: 0 0 0 #fff;
            filter: alpha(opacity=100);
            opacity: 1;
            outline: none;
            margin-top: 0;
        }

        .modal-heading .close {
            margin-top: 8px;
        }

    .terms-conditions {
        margin-top: 20px;
        font-size: 11px;
    }

    .modal .terms-conditions {
        margin-top: 10px;
        text-align: left;
    }

        .modal .terms-conditions a {
            text-decoration: underline;
            color: inherit;
        }

    .modal-content {
        position: relative;
        background-clip: padding-box;
        border-radius: 0;
        outline: 0 none;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
        background-color: #fff;
        border: 1px solid rgb(64, 64, 64);
    }



    /* Modal Success
===========================================================*/

    .modal-success {
        background: #0083ae;
        color: #fff;
        border: 0;
    }

        .modal-success .modal-header {
            border-bottom: 0;
            border-radius: 4px;
        }


        .modal-success h4 {
            font-weight: 400;
            text-transform: none;
        }

        .modal-success .close {
            color: #fff;
            border: 0;
            padding-top: 10px;
            text-shadow: 0 0 0 transparent;
            filter: alpha(opacity=100);
            opacity: 1;
            outline: none;
            outline: 0;
            background-color: transparent;
        }

            .modal-success .close:hover, .modal-success .close:focus, .modal-success .close:active {
                color: #404040;
            }


    /* Sections
===========================================================*/
    section {
        padding: 0;
        width: 100%;
        position: relative;
    }


    /* Sections Titles
===========================================================*/

    .section-heading {
        padding-bottom: 30px;
    }

    .section-title {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: .02em;
    }

    .section-subheading {
        font-size: 16px;
        line-height: 1.3em;
        font-weight: 300;
        margin-top: 10px;
    }

    .section-title-line {
        display: block;
        width: 60px;
        height: 4px;
        background-color: #fff;
        margin-top: 10px;
    }


    /* HR Spacer
===========================================================*/

    .hr-spacer {
        background-color: rgba(255,255,255, 0.9);
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border-width: 1px 0 0;
        border-style: solid none none;
        border-color: #808080 -moz-use-text-color -moz-use-text-color;
        -moz-border-top-colors: none;
        -moz-border-right-colors: none;
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        border-image: none;
    }



    /* Breadcrumbs
===========================================================*/
    .breadcrumbs {
        position: relative;
        /*background-image: url(../img/cubes.png);*/
        overflow: hidden;
        border-bottom: solid 1px #eee;
        border-top: solid 1px #eee;
    }

        .breadcrumbs h3 {
            color: #666;
            margin-top: 18.5px;
            font-size: 24px;
            font-weight: 100;
            padding-left: 15px;
        }

    .breadcrumb {
        top: 13px;
        background: none;
        position: relative;
    }

    ul.breadcrumb {
        padding-right: 15px;
    }

    .breadcrumb li.active,
    .breadcrumb li a:hover {
        color: #ffffff;
        text-decoration: none;
    }

    /*beadcrumb style 1*/
    .breadcrumbs.style1 {
        background: #262626;
        border: 0;
    }

        .breadcrumbs.style1 h3 {
            color: #fff;
        }

        .breadcrumbs.style1 ul.breadcrumb li a {
            color: #fff;
        }

        .breadcrumbs.style1 li + li:before {
            color: #fff;
        }

        .breadcrumbs.style1 ul.breadcrumb li a:hover {
            color: #21252b;
            text-decoration: none;
        }

    /*beadcrumb parallax*/
    .breadcrumbs.section-parallax {
        border: 0;
    }

        .breadcrumbs.section-parallax h3 {
            color: #fff;
        }

        .breadcrumbs.section-parallax ul.breadcrumb li a {
            color: #01c1c1;
        }

        .breadcrumbs.section-parallax li + li:before {
            color: #fff;
        }

        .breadcrumbs.section-parallax ul.breadcrumb li.active {
            color: #fff;
        }

        .breadcrumbs.section-parallax ul.breadcrumb li a:hover {
            color: #fff;
            text-decoration: none;
        }

    /*CSS for serving the retina image to devices with a high "device-pixel-ratio":*/
    @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
        .breadcrumbs {
            /*background: #fafafa url(../img/cubes_@2X.png);*/
        }
    }

    @media (max-width: 480px) {
        .breadcrumbs h3 {
            width: 100%;
            text-align: center;
        }

        .breadcrumb {
            width: 100%;
            text-align: center;
        }
    }



    /* Select 2 Dropdowns
===========================================================*/

    .select2-container {
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
        z-index: 999;
    }

        .select2-container .select2-selection--single {
            box-sizing: border-box;
            cursor: pointer;
            display: block;
            height: 50px;
        }

    .select2-container--default .select2-selection--single {
        background-color: #FFF;
        border: 1px solid #e0e0e0;
        border-radius: 0;
    }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
            color: #444;
            line-height: 46px;
            font-weight: 400;
        }

    .select2-container .select2-selection--single .select2-selection__rendered {
        display: block;
        padding-left: 8px;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 50px;
        position: absolute;
        top: 1px;
        right: 1px;
        width: 20px;
    }

    .select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: #262626;
        color: #FFF;
    }

    .select2-container--default.select2-container--disabled .select2-selection--single {
        background-color: #d9d9d9;
        cursor: default;
    }

    .select2-container--open {
        z-index: 55000 !important;
    }


    .select2-container--default .select2-selection--single:focus,
    .select2-container--default .select2-selection--single:active,
    .select2-search--dropdown .select2-search__field {
        outline: thin dotted;
        outline: 0;
        outline-offset: -2px;
    }



    /* Additional Size
===========================================================*/

    .input45 {
        width: 47.5%;
    }

    .to {
        display: inline-block;
        float: left;
        padding: 9px 5px;
        text-align: center;
        width: 5%;
        color: #595959;
    }

    @media(max-width: 767px) {
        .modal .input45 {
            width: 100%;
            float: none;
            display: block;
        }

        .modal .to {
            width: 100%;
            float: none;
            padding-top: 0;
        }
    }

    .staff-member {
        height: 468px;
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        flex: 0 1 100%;
    }

    @media screen and (min-width: 728px) {
        .card {
            flex: 0 1 48%;
            margin: 0 1% 2em;
        }
    }
    @media screen and (min-width: 1200px) {
        .card {
            flex: 0 1 23%;
        }
    }


    .carousel-control {
        visibility: visible;
    }


    @media screen and (min-width: 250px) {
        .one {
            visibility: hidden;
        }

        .two, .three, .four {
            visibility: visible;
        }
    }


    @media screen and (min-width: 768px) {
        .one, .two {
            visibility: hidden;
        }

        .three, .four {
            visibility: visible;
        }
    }

    @media screen and (min-width: 952px) {
        .one, .two {
            visibility: hidden;
        }

        .three, .four {
            visibility: visible;
        }
    }

    @media screen and (min-width: 992px) {
        .one, .two, .three, .four {
            visibility: hidden;
        }

        .three-custom, .four-custom {
            visibility: visible;
        }
    }

    @media screen and (min-width: 1200px) {
        .one, .two, .three, .four, .three-custom, .four-custom {
            visibility: hidden;
        }
    }

    /* Page Titles
===========================================================*/

    .page-title-headings h2,
    .page-title-headings h1 {
        font-size: 30px;
        color: #0083AE;
        width: 100%;
        letter-spacing: 0.1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 10px;
        margin-bottom: 28px;
    }


    .subhead-title {
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #EEE;
        font-weight: 200;
    }

        .subhead-title span {
            padding-bottom: 6px;
            border-bottom: 1px solid #0083AE;
            font-weight: 400;
            text-transform: uppercase;
        }

        .subhead-title:last-of-type {
            margin-top: 50px;
            margin-bottom: 5px;
        }

    .title-btn-float {
        float: right;
    }

    @media screen and (max-width: 991px) {
        .subhead-title {
            margin-top: 50px;
        }

            .subhead-title:last-of-type {
                margin-bottom: -20px;
            }
    }


    @media screen and (max-width: 767px) {
        .page-title-headings h2,
        .page-title-headings h1 {
            margin-top: 65px;
            font-size: 24px;
            padding: 0 15px;
        }

        .title-btn-float {
            display: block;
            float: none;
            margin-bottom: 10px;
        }

            .title-btn-float .btn-small {
                width: 100%;
            }
    }



    /* Columns
===========================================================*/
    .column200 {
        -webkit-column-width: 200px;
        -moz-column-width: 200px;
        column-width: 200px;
    }

    .column220 {
        -webkit-column-width: 220px;
        -moz-column-width: 220px;
        column-width: 220px;
    }

    .column300 {
        -webkit-column-width: 300px;
        -moz-column-width: 300px;
        column-width: 300px;
    }

        .column220 li,
        .column300 li {
            padding-right: 10px;
            padding-bottom: 4px;
        }



    /*===================================================================================================
  Disclaimer
===================================================================================================*/
    .disclaimer {
        border-top: 1px solid #ddd;
        padding: 40px 0;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
    }

    /* http://stackoverflow.com/a/23741988 */
    .row-full-width > *[class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    /*
    Center captcha in div
*/
    .recaptcha > div {
        margin: 0 auto;
    }

    #custom .container-fluid {
        padding: 0;
    }


/*===================================================================================================
  Sitemap
===================================================================================================*/
    .sitemap {
        z-index: 1;
    }

    .sitemap h4 {
        padding-left: 30px;
    }

    .sitemap > div > ul li a {
        color: #337ab7;
    }

    div.sitemap > div {
        float: left;
    }


/*===================================================================================================
  Lazy Load
===================================================================================================*/
    .default-src-error {
    }

    @-webkit-keyframes Animation {
        0% {
            background-position: 10% 0%;
        }

        50% {
            background-position: 91% 100%;
        }

        100% {
            background-position: 10% 0%;
        }
    }

    @-moz-keyframes Animation {
        0% {
            background-position: 10% 0%;
        }

        50% {
            background-position: 91% 100%;
        }

        100% {
            background-position: 10% 0%;
        }
    }

    @keyframes Animation {
        0% {
            background-position: 10% 0%;
        }

        50% {
            background-position: 91% 100%;
        }

        100% {
            background-position: 10% 0%;
        }
    }

    /*===================================================================================================
  General Padding 
===================================================================================================*/


@media (min-width: 767px) {
    .price, .find, .seonamedlinks-standard {
        padding: 15px 0 15px 0;
    }
}



    /*===================================================================================================
  Carousel
===================================================================================================*/
    .carousel-control {
        visibility: visible;
    }

/* *****************************************
*  SEO Named Links same for all templates.
******************************************* */
.seo-footer {
    background-color: #222;
    color: white;
    padding: 50px 0;
}

    .seo-footer section:not(:last-child) {
        margin-bottom: 50px;
    }

    .seo-footer .seo-heading {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: .04em;
        text-transform: uppercase;
        margin: 0 0 20px;
        padding: 0;
    }

    .seo-footer .seo-links {
        margin: 0;
        line-height: 1.8;
        padding-left: 15px;
    }

    .seo-footer .seo-link {
        color: white;
        -webkit-transition: all .25s ease;
        -o-transition: all .25s ease;
        transition: all .25s ease;
        border-bottom: 1px solid transparent;
    }

        .seo-footer .seo-link:hover {
            opacity: .7;
        }

    .seo-footer .seo-count {
        font-size: .8em;
        color: white;
        opacity: .8;
    }

@media screen and (min-width: 450px) {
    .seo-footer .seo-links {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
        -moz-column-fill: balance;
        -webkit-column-fill: balance;
        column-fill: balance;
        -webkit-column-gap: 10px;
        column-gap: 10px;
    }
}

@media screen and (min-width: 768px) {
    .seo-footer .seo-links {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
        -moz-column-fill: balance;
        -webkit-column-fill: balance;
        column-fill: balance;
        -webkit-column-gap: 10px;
        column-gap: 10px;
    }
}

@media screen and (max-width: 450px) {
    .seo-footer {
        text-align: center;
    }

        .seo-footer .seo-links {
            padding-left: 0;
        }
}

/*===================================================================================================
  Footer Map
===================================================================================================*/
.map-m1 #map-canvas {
    width: 100%;
    height: 300px;
    margin: 0;
}

.locations-address {
    margin-bottom: 0;
}

.carfax-icon {
    background: #fff;
    width: 120px;
}