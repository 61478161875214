.seo-footer {
    background-color: #222;
    color: #fff;
    padding: 25px 0;
}

    .seo-footer section:not(:last-child) {
        margin-bottom: 50px;
    }

    .seo-footer .seo-heading {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: .04em;
        text-transform: uppercase;
        margin: 0 0 20px;
        padding: 0;
    }

    .seo-footer .seo-links {
        margin: 0;
        line-height: 1.8;
        padding-left: 15px;
    }

    .seo-footer .seo-link {
        color: white;
        transition: all .25s ease;
        border-bottom: 1px solid transparent;
    }

        .seo-footer .seo-link:hover {
            opacity: .7;
        }

    .seo-footer .seo-count {
        font-size: .8em;
        color: #fff;
        opacity: .8;
    }

    .seo-footer .seo-toggle {
        color: currentColor;
        display: block;
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
    }

    .seo-footer .seo-toggle--open::after,
    .seo-footer .seo-toggle--closed::after {
        font-family: "FontAwesome";
        font-size: 1.25em;
        font-weight: normal;
        line-height: 1;
        pointer-events: none;
        position: absolute;
        right: 18px;
        top: -3px;
    }

    .seo-footer .seo-toggle--open::after {
        content: "\f106";
    }

    .seo-footer .seo-toggle--closed::after {
        content: "\f107";
    }

    .seo-footer .seo-panel {
        overflow: hidden;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        transition: height 400ms;
        will-change: height;
    }

    .seo-footer .seo-panel--open {
        height: auto;
    }

    .seo-footer .seo-panel--closed {
        height: 0;
    }

@media screen and (min-width: 450px) {
    .seo-footer .seo-links {
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-fill: balance;
        column-fill: balance;
        -webkit-column-gap: 10px;
        column-gap: 10px;
    }
}

@media screen and (min-width: 768px) {
    .seo-footer .seo-links {
        -webkit-column-count: 4;
        column-count: 4;
        -webkit-column-fill: balance;
        column-fill: balance;
        -webkit-column-gap: 10px;
        column-gap: 10px;
    }

    .seo-footer .seo-toggle {
        cursor: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .seo-footer .seo-toggle::after {
        visibility: hidden;
    }

    .seo-footer .seo-panel--open,
    .seo-footer .seo-panel--closed {
        /* "!important" used to override explicitly set height from JavaScript */
        height: auto !important;
    }
}

@media screen and (max-width: 450px) {
    .seo-footer {
        text-align: center;
    }

        .seo-footer .seo-links {
            padding-left: 0;
        }
}
