/* Welcome ===========================================================*/
.welcome-centerbox {
    padding: 8em 1.5em;
    position: relative;
}

.welcome-centerbox .welcome-m1 {
    text-align: center;
    position: relative;
    z-index: 4;
    display: block;
    padding: 1.5em 1em 2em 1em;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

@media (max-width: 767px) {
    .welcome-centerbox {
        padding: 5em 1em;
    }
}

@media (max-width: 567px) {
    .welcome-centerbox {
        padding: 3em 1em;
    }

    .welcome-centerbox .btn {
        width: 100%;
        display: block;
    }
}

.welcome-centerbox .welcome-section h2.welcome-name span {
    display: block;
    font-size: 16px;
}



