
/* Simple Header ===========================================================*/

.simpleheader-m1 {
    text-align: center;
    padding-top: .4em;
    padding-bottom: 1em;
}

@media(max-width:767px) {
    .simple-header {
        display: none
    }
}

.simple-header a,
.simple-header a:hover {
    text-decoration: none;
}
