.single-card-carousel {
    color: #fff;
    background: #ddd;
    margin-top: 0;
    margin-bottom: 0;
    padding: 50px 0 80px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

@media(max-width: 767px) {
    .single-card-carousel {
        padding: 30px 0 70px 0;
    }
}

.single-card-carousel:after {
    content: '';
    position: absolute;
    top: 0;
    left: -400px;
    width: 80%;
    height: 2000px;
    z-index: 3;
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
    -o-box-shadow: none;
    margin-top: -600px;
    vertical-align: top;
    overflow: hidden;
    background: url("/images/rushmore-pattern.png") repeat;
    -webkit-backface-visibility: hidden;
}

.h-inventory-singlecarousel-m1 {
    position: relative;
    z-index: 9;
}

    .h-inventory-singlecarousel-m1 .section-heading {
        display: none;
    }

.h-inventory-singlecarousel-btn {
    display: none;
}



/* Carousel
===========================================================*/



.h-inventory-singlecarousel-m1 .single.carousel-control,
.h-inventory-singlecarousel-m1 .single.carousel-control.left,
.h-inventory-singlecarousel-m1 .single.carousel-control.right {
    background-image: none;
}

    .h-inventory-singlecarousel-m1 .single.carousel-control .glyphicon-chevron-left,
    .h-inventory-singlecarousel-m1 .single.carousel-control .glyphicon-chevron-right,
    .h-inventory-singlecarousel-m1 .single.carousel-control .icon-prev,
    .h-inventory-singlecarousel-m1 .single.carousel-control .icon-next {
        line-height: 38px;
        color: #fff;
        background: rgba(0,0,0,0.2);
        width: 40px;
        height: 40px;
        text-align: center;
        vertical-align: middle;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -o-border-radius: 2px;
    }

    .h-inventory-singlecarousel-m1 .single.carousel-control:hover .glyphicon-chevron-left,
    .h-inventory-singlecarousel-m1 .single.carousel-control:hover .glyphicon-chevron-right,
    .h-inventory-singlecarousel-m1 .single.carousel-control:hover .icon-prev,
    .h-inventory-singlecarousel-m1 .single.carousel-control:hover .icon-next {
        background: rgba(0,0,0,0.8);
    }

.h-inventory-singlecarousel-m1 .single.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
    filter: alpha(opacity=100);
    opacity: 1;
}

    .h-inventory-singlecarousel-m1 .single.carousel-control.right {
        right: 0;
        left: auto;
    }

@media screen and (max-width: 768px) {
    .h-inventory-singlecarousel-m1 .single.carousel-control .glyphicon-chevron-left,
    .h-inventory-singlecarousel-m1 .single.carousel-control .glyphicon-chevron-right,
    .h-inventory-singlecarousel-m1 .single.carousel-control .icon-next,
    .h-inventory-singlecarousel-m1 .single.carousel-control .icon-prev {
        font-size: 20px;
        top: 30%;
    }
}



.h-inventory-singlecarousel-m1 .single.carousel-inner > .item.active,
.h-inventory-singlecarousel-m1 .single.carousel-inner > .item.next.left,
.h-inventory-singlecarousel-m1 .single.carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

@media not all, (-webkit-transform-3d) {
    .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.active,
    .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.next.left,
    .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.prev.right {
        left: 0;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
}


@media all and (-webkit-transform-3d) {
    .h-inventory-singlecarousel-m1 .single.carousel-inner > .item {
        -webkit-transition: -webkit-transform .6s ease-in-out;
        -o-transition: -o-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }

        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.next,
        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.active.right {
            left: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.prev,
        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.active.left {
            left: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }

        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.next.left,
        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.prev.right,
        .h-inventory-singlecarousel-m1 .single.carousel-inner > .item.active {
            left: 0;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
}


/* Homepage Vehicle Cards
===========================================================*/
.h-inventory-singlecarousel-m1 .vehicle-card {
    position: relative;
    margin-bottom: 5px;
    color: #262626;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

.h-inventory-singlecarousel-m1 .vehicle-card .vehicle-photo {
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    border-radius: 0;
    width: 50%;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 502px;
}


.h-inventory-singlecarousel-m1 .vehicle-card .vehicle-info {
    padding: 15px 20px 15px 20px;
    border-left: 0;
    border-right: 0;
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    width: 50%;
    position: relative;
}

.h-inventory-singlecarousel-m1 .vehicle-card .vehicle-info .vehicle-phone {
    font-size: 16px;
    font-weight: 200;
}

.h-inventory-singlecarousel-m1 .vehicle-card .vehicle-photo img {
    width: 100%;
    border-bottom: 0 solid #FFF;
}

.h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    font-size: 3.9286em;
    font-weight: 700;
    line-height: 1;
    letter-spacing: .02em;
    margin-top: 10px;
    margin-bottom: 10px;
}

.h-inventory-singlecarousel-m1 .specs {
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 0;
    color: #595959;
    height: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.h-inventory-singlecarousel-m1 .specs li {
    padding-bottom: 3px;
}

.h-inventory-singlecarousel-m1 .specs li:before {
    font-family: FontAwesome;
    content: "\f101";
    opacity: .5;
    padding-right: 5px;
}



@media(max-width: 1200px) {
    .h-inventory-singlecarousel-m1 .specs {
        height: 63px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 20px;
    }

    .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 39px;
        margin-top: 0;
    }

    .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-price {
        padding: 8px 20px;
    }
}

@media(max-width:900px) {
    .h-inventory-singlecarousel-m1 .specs {
        margin-top: 10px;
    }

    .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 34px;
    }
}

@media screen and (max-width: 768px) {
    .h-inventory-singlecarousel-m1 .vehicle-card {
        display: block;
    }

        .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-photo {
            height: auto;
            width: 100%;
        }

        .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-info {
            text-align: left;
            width: 100%;
        }

        .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
            font-size: 23px;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-price {
            top: 0;
            bottom: auto;
        }

    .h-inventory-singlecarousel-m1 .specs {
        margin-top: 0;
    }
}

.h-inventory-singlecarousel-m1 .no-vehicles-found {
    width: 500px;
    max-width: 100%;
    background-color: #ffcccc;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
}
