.h1title-block .page-title-headings h1 {
    text-align: center;
    border-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0.5em 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.h1title-underline .page-title-headings h1 {
    line-height: 48px;
}