.welcome-center {
    margin-top: 0;
    padding: 0;
    min-height: 200px;
}

.welcome-center .welcome-m1 {
	text-align: center;
	padding: 150px 0;
	z-index: 4;
	position: relative;
}

.welcome-center .welcome-m1 .welcome-section {
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 25px;
}

.welcome-center .welcome-m1 .header-logo {
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 10px;
}

.welcome-center .welcome-m1 .welcome-name {
	font-size: 40px;
	margin-bottom: 10px;
	font-weight: 700;
}

.welcome-center .welcome-m1 .welcome-name span {
	font-weight: 400;
	display: block;
	font-size: 17px;
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.welcome-center .welcome-m1 .welcome-phone {
	display: none;
}

.welcome-center .welcome-m1 .welcome-btn {
	display: inline-block;
}
